@font-face {
  font-family: 'ManifontGroteskBook';
  src: url(./fonts/ManifontGrotesk/ManifontGroteskBook-webfont.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ManifontGroteskBold';
  src: url(./fonts/ManifontGrotesk/ManifontGroteskBold-webfont.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'Avara';
  src: url(./fonts/Avara/Avara-Bold.woff2) format('woff2'),
    url(./fonts/Avara/Avara-Bold.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}


/* @font-face {
  font-family: 'Inter';
  src: url(./fonts/Inter/inter-v11-latin-regular.woff2) format('woff2'),
    url(./fonts/Inter/inter-v11-latin-regular.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Inter-bold';
  src: url(./fonts/Inter/inter-v11-latin-700.woff2) format('woff2'),
    url(./fonts/Inter/inter-v11-latin-700.woff) format('woff');
  font-weight: normal;
  font-style: normal;
} */

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: black;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
  font-size: 18px;
  font-family: 'ManifontGroteskBook', 'Helvetica', sans-serif;
  background-image: url(./images/pattern_attension_24.gif);
  background-repeat: repeat;
  background-size: 330px;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ios-standalone .ios-extra-padding {
  padding-bottom: 2rem;
}

/* remove this blue/green square when clicked on mobile */
* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a {
  /* color: white; */
  color: #FF9580;
  text-decoration: none;
}

#root {
  max-width: 1000px;
  min-height: 100%;
  position: relative;
  margin: auto;
  outline: 2px solid white;
}

h1 {
  font-family: 'Inter-bold';
  font-size: 32px;
}

h3 {
  font-family: 'Inter-bold';
  font-size: 24px;
  text-transform: uppercase;
}

input:focus,
textarea:focus,
select:focus,
button:focus {
  outline: none;
}

.mapboxgl-user-location-dot:before,
.maplibregl-user-location-dot:before {
  left: 0px;
}

.maplibregl-popup-content {
  background: #241b24 !important;
  height: 0px;
  display: flex;
  align-items: center;
  font-size: 10px;
  padding: 10px 5px !important;
  z-index: 3;
}

.maplibregl-popup-tip {
  border-bottom-color: #241b24 !important;
}

.maplibregl-user-location-dot {
  background-color: #eb1df2 !important;
}

.maplibregl-user-location-dot::before {
  background-color: #eb1df2 !important;
}

.maplibregl-user-location-accuracy-circle {
  background-color: #ea1df233 !important;
}

.maplibregl-ctrl {
  display: none;
}

.tentMarker {
  background-image: url('./images/map-layers/tent-open.webp');
  &.locked {
    background-image: url('./images/map-layers/tent-locked.webp') !important;
  }
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #4c414e;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: #4c414e;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: #4c414e;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: #4c414e;
}
