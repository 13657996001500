.buttons {
  margin: 10px;
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  z-index: 1;
}

.right {
  right: 2px;
}

.map {
  width: 100%;
  height: 100vh;
  background-color: #e5dcda;
}

.info {
  z-index: 1;
  position: absolute;
  font: 12px/20px Helvetica Neue, Arial, Helvetica, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 105px;
  width: 100%;
}

.info > .message {
  color: #2d242e;
  background-color: white;
  border-radius: 4px;
  padding: 4px 8px;
  margin: 0 15px;
  -webkit-box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.31);
  box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.31);
}
